<template>
  <div>
    <!-- <breadcrumbs /> -->
    <alert-connect-amazon v-if="$route.meta.user !== 'unsigned'" />
    <div class="d-flex justify-space-between align-end">
      <div class="title d-block mb-2">{{ $t("Store") }}</div>
        <div class="text-end mb-2">
          <select-amazon-market v-if="!$store.state.filter.toggleFilter" />
          <filter-button class="ml-2" v-if="loadConnected && $route.meta.user !== 'unsigned'"/>
        </div>
    </div>
    <products-filter v-if="loadConnected && $route.meta.user !== 'unsigned'" :loading="loading"/>
    <v-card class="mt-2">
      <div :class="$vuetify.theme.isDark ? 'v-orders-dark' : 'v-orders'">
        <div class="d-flex justify-end">
          <products-search @updateField="updateSearch"/>
        </div>
        <products-table :loadConnected="loadConnected" @loading="updateLoading" :updateSearch="search" class="mt-2"/>
      </div>
    </v-card>
  </div>
</template>

<script>
import ProductsTable from './ProductsTable'
import ProductsFilter from './ProductsFilter'
import FilterButton from '@/views/market/components/FilterButton'
import ProductsSearch from '@/views/market/components/ProductsSearch'
import AlertConnectAmazon from '@/views/market/components/AlertConnectAmazon'
import SelectAmazonMarket from '@/views/market/components/SelectAmazonMarket'
import { ref, computed, onMounted } from '@vue/composition-api'
import {useRouter} from "@core/utils";
import store from "@/store";

export default {
  name: "ProductsList",
  components: {
    ProductsSearch,
    ProductsTable,
    ProductsFilter,
    FilterButton,
    AlertConnectAmazon,
    SelectAmazonMarket
  },
  setup() {
    const {route, router} = useRouter()
    const loading = ref(false)
    const search = ref('')
    const loadConnected = ref(false)
    const updateLoading = (e) => loading.value = e
    const updateSearch = (e) => search.value = e
    const queryParams = computed(({
      get() {
        return store.getters['filter/queryParams']
      },
      set(val) {
        store.commit("filter/SET_QUERY_PARAMS", val)
      }
    }))
    const unsignedUrlFilter = () => {
      if(route.value.query.marketplace && store.getters['connect/marketplaces'].includes(route.value.query.marketplace)) {
        store.commit("filter/SET_SAVED_FILTER_FROM_URL", {
          marketplace: route.value.query.marketplace
        })
      }
      if(store.getters['filter/savedFilterData'].hasOwnProperty('marketplace')) {
        if(store.getters['filter/savedFilterData'].marketplace != store.state.filter.defaultMarketplace) {
          queryParams.value = {marketplace: store.getters['filter/savedFilterData'].marketplace}
        }
      }
      updateUrlFilter()
    }
    const updateUrlFilter = () => router.replace({name: route.value.name, query: queryParams.value, params: { savePosition: true }}).catch(()=>{})
    onMounted(async () => {
      await store.dispatch('connect/connectedAmzAccounts').then(() => {
        loadConnected.value = true
      })
      if(route.value.meta.user == 'unsigned') unsignedUrlFilter()
    })
    return {
      loadConnected,
      search,
      loading,
      updateLoading,
      updateSearch
    }
  }
}
</script>
