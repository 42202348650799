<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn-toggle class="deals-select-marketplace" borderless>
        <v-btn
            v-bind="attrs"
            v-on="on"
            outlined
            color="primary"
        >
          {{ filterData.marketplace }}
        </v-btn>
        <v-btn 
          v-bind="attrs"
          v-on="on"
          outlined
          color="primary"
          class="px-1 no-active"
        >
          <v-icon color="white">{{ mdiMenuDown }}</v-icon>
        </v-btn>
      </v-btn-toggle>
    </template>
    <div v-if="amzMarketplaces">
      <v-btn 
        v-for="market in amzMarketplaces"
        :key="market"
        block
        class="deals-select-item"
        @click="changeMarket(market)"
      >
        {{ $t(market) }}
      </v-btn>
    </div>
  </v-menu>
</template>


<script>
import {mdiMenuDown} from '@mdi/js';
import {ref, computed} from '@vue/composition-api'
import store from '@/store'
import { useRouter } from '@core/utils'
import Vue from 'vue'

export default {
  setup() {
    const { route, router } = useRouter()
    const filterData = computed(({
      get() {
        return store.getters['filter/filterData']
      },
      set(val) {
        store.commit("filter/SET_FILTER_DATA", val)
      }
    }))
    const queryParams = computed(({
      get() {
          return store.getters['filter/queryParams']
      },
      set(val) {
          store.commit("filter/SET_QUERY_PARAMS", val)
      }
    }))
    const amzMarketplaces = computed(() => {
      if(store.getters['filter/constFilter'].hasOwnProperty('amz_categories')) {
        return Object.entries(store.getters['filter/constFilter'].amz_categories).map(([k, v]) => (k))
      }
    })
    const changeMarket = (market) => {
      if(market == store.state.filter.defaultMarketplace) {
        Vue.delete(queryParams.value, 'marketplace')
      } else {
        queryParams.value.marketplace = market
      }
      filterData.value = Object.assign(filterData.value, {'marketplace': market})
      store.commit("filter/SET_SAVED_FILTER_DATA", {'marketplace': market})
      router.replace({name: route.value.name, query: queryParams.value, params: { savePosition: true }}).catch(()=>{})
      store.commit("filter/UPDATE_FILTER")
      store.dispatch("filter/getFilterData")
    }
    return {
      changeMarket,
      amzMarketplaces,
      filterData,
      mdiMenuDown
    }
  }
}
</script>