<template>
   <transition type="animation" name="backdrop" appear>
    <v-card v-if="$store.state.filter.toggleFilter" class="mb-4 text-fs-14 deals-shadow default-filter mobile-filter">
        <v-row class="mt-2 px-4 d-flex justify-space-between" v-if="filterData">
            <v-col lg="2" sm="12" md="12">
                <div class="mb-4">
                    <span>{{ $t('Amazon Marketplace') }}</span>
                    <v-select
                        v-model="filterData.marketplace"
                        :items="amzMarketplaces"
                        filled
                        dense
                        menu-props="auto"
                        hide-details
                        single-line
                        @change="changeMarketplace"
                        class="pt-2"
                    />
                </div>
                <div class="mb-4">
                    <span>{{ $t('Category') }}</span>
                    <v-select
                        v-model="filterData.category"
                        :items="amzCategories"
                        filled
                        dense
                        menu-props="auto"
                        hide-details
                        single-line
                        @change="changeCategory"
                        class="pt-2"
                    />
                </div>
                <div>
                    <VTextField
                        :label="$t('Supplier')" 
                        v-model="filterData.supplier"
                        @keyup="changeSupplier"
                    />
                </div>
            </v-col>
            <v-col lg="3" sm="12" md="12">
                <div>
                    <div class="text-center">{{ $t('Red Flags') }}</div>
                    <div class="d-flex justify-end">
                        <span class="mr-5">{{ $t('Yes') }}</span>
                        <span class="mr-4">{{ $t('No') }}</span>
                        <span class="mr-2">{{ $t('Any') }}</span>
                    </div>
                    <div>
                        <span v-for="(flag, index) in redFlags" style="white-space: nowrap">
                            <VRadioGroup
                                v-model="filterData[index]"
                                class="red-flags-radio-group"
                                hide-details
                                @change="changeRedFlad($event, index)"
                            >
                                <div class="mr-4 d-flex">
                                    <v-icon
                                        size="20"
                                        v-if="index !== 'red_flag_asin_parent' && index !== 'red_flag_is_oversize'"
                                    >
                                        {{ getRedFlagIcon(index) }}
                                    </v-icon>
                                    <span v-else-if="index === 'red_flag_asin_parent'" style="width: 20px">
                                        <svg width="17" height="17" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" style="opacity: 0.8">
                                            <g clip-path="url(#clip0_2981_124443)">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M6.32107 2.5C6.32107 2.22386 6.54493 2 6.82107 2H10.2948C10.5709 2 10.7948 2.22386 10.7948 2.5V5.97368C10.7948 6.24983 10.5709 6.47368 10.2948 6.47368H6.82107C6.54493 6.47368 6.32107 6.24983 6.32107 5.97368V2.5ZM0.0585938 14.1312C0.0585938 13.8551 0.282451 13.6312 0.558594 13.6312H2.09612V9.15833V8.95833H2.29612H8.35791V6.47412H8.35927H8.37967H8.75791H8.75927H8.77966V8.95833H14.8211H15.0211V9.15833V13.6312H16.5591C16.8352 13.6312 17.0591 13.8551 17.0591 14.1312V17.6049C17.0591 17.8811 16.8352 18.1049 16.5591 18.1049H13.0854C12.8093 18.1049 12.5854 17.8811 12.5854 17.6049V14.1312C12.5854 13.8551 12.8093 13.6312 13.0854 13.6312H14.6211V9.35833H8.77966V13.6312H10.2948C10.5709 13.6312 10.7948 13.8551 10.7948 14.1312V17.6049C10.7948 17.8811 10.5709 18.1049 10.2948 18.1049H6.82107C6.54493 18.1049 6.32107 17.8811 6.32107 17.6049V14.1312C6.32107 13.8551 6.54493 13.6312 6.82107 13.6312H8.37967V9.35833H8.35791H2.49612V13.6312H4.03228C4.30842 13.6312 4.53228 13.8551 4.53228 14.1312V17.6049C4.53228 17.8811 4.30842 18.1049 4.03228 18.1049H0.558594C0.282452 18.1049 0.0585938 17.8811 0.0585938 17.6049V14.1312Z" fill="currentColor"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_2981_124443">
                                                    <rect width="16.9412" height="24" fill="white" transform="translate(0.0585938)"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                    <div v-else-if="index === 'red_flag_is_oversize'" style="width: 20px" class="d-flex align-baseline justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="14px" viewBox="0 0 16 16" version="1.1" style="margin-top: 2px">
                                            <g id="surface1">
                                                <path style=" stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:0.8;" d="M 15.921875 5.769531 L 14.339844 1.023438 C 14.136719 0.414062 13.566406 0 12.917969 0 L 8.5 0 L 8.5 6 L 15.960938 6 C 15.945312 5.921875 15.945312 5.84375 15.921875 5.769531 Z M 7.5 0 L 3.082031 0 C 2.433594 0 1.863281 0.414062 1.660156 1.023438 L 0.078125 5.769531 C 0.0546875 5.84375 0.0546875 5.921875 0.0390625 6 L 7.5 6 Z M 0 7 L 0 14.5 C 0 15.328125 0.671875 16 1.5 16 L 14.5 16 C 15.328125 16 16 15.328125 16 14.5 L 16 7 Z M 0 7 "/>
                                            </g>
                                        </svg>
                                    </div>
                                    <span class="ml-2">
                                        {{ redFlagsText[index] }}
                                    </span>
                                </div>
                                <div class="d-flex">
                                    <VRadio
                                        value="yes"
                                        class="mr-2"
                                    />
                                    <VRadio
                                        value="no"
                                        class="mr-2"
                                    />
                                    <VRadio
                                        :value="null"
                                    />
                                </div>
                            </VRadioGroup>
                        </span>
                    </div>
                </div>
            </v-col>
            <v-col lg="2" sm="12" md="12">
                <div>
                    <span>{{ $t('Status') }}</span>
                    <v-select
                        v-model="filterData.status"
                        :items="statusItems"
                        filled
                        dense
                        menu-props="auto"
                        hide-details
                        single-line
                        @change="changeStatus"
                        class="pt-2"
                    />
                </div>
                <div class="mt-2">
                    <span>{{ $t('Created') }}</span>
                    <DatePicker v-model="createdRange" :placeholder="getDateTimePlaceholder()" range format="YYYY-MM-DD" value-type="format" class="width-auto pt-2" />
                </div>
                <div class="mt-2">
                    <span>{{ 'Store ratings' }}</span>
                    <v-row style="flex-flow: nowrap">
                        <v-col style="max-width: min-content;padding-right: 0;">
                            <v-checkbox dense hide-details v-model="rating.gold" @change="changeMarketRating" class="mt-0 mr-2">
                                <template v-slot:label>
                                    <span class="text-fs-14">{{ $t("Gold") }}</span>
                                </template>
                            </v-checkbox>
                            <v-checkbox dense hide-details v-model="rating.silver" @change="changeMarketRating" class="mt-0">
                                <template v-slot:label>
                                    <span class="text-fs-14">{{ $t("Silver") }}</span>
                                </template>
                            </v-checkbox>
                        </v-col>
                        <v-col style="max-width: min-content;padding-right: 0;">
                            <v-checkbox dense hide-details v-model="rating.bronze" @change="changeMarketRating" class="mt-0">
                                <template v-slot:label>
                                    <span class="text-fs-14">{{ $t("Bronze") }}</span>
                                </template>
                            </v-checkbox>
                            <v-checkbox dense hide-details v-model="rating.wooden" @change="changeMarketRating" class="mt-0">
                                <template v-slot:label>
                                    <span class="text-fs-14">{{ $t("Wooden") }}</span>
                                </template>
                            </v-checkbox>
                        </v-col>
                    </v-row>
                </div>
                <div class="mt-4">
                    <span>{{ $t('30d avg BSR') }}</span>
                    <div class="range-value mt-2">
                        <input 
                            :placeholder="constFilter.avg_bsr_min"
                            type="number"
                            :min="constFilter.avg_bsr_min"
                            v-model="filterData.avg_bsr_min"
                            class="range-item"
                            @blur="blurHandler($event, 'avg_bsr_min')"
                            @keyup="inputHandler($event, 'avg_bsr_min')"
                        />
                        <input
                            :placeholder="constFilter.avg_bsr_max"
                            type="number"
                            :max="constFilter.avg_bsr_max"
                            v-model="filterData.avg_bsr_max"
                            class="range-item"
                            @blur="blurHandler($event, 'avg_bsr_max')"
                            @keyup="inputHandler($event, 'avg_bsr_max')"
                        />
                    </div>
                </div>
            </v-col>
            <v-col lg="2" sm="12" md="12">
                <div>
                    <span>{{ $t('Cost of Goods (COG)') }}</span>
                    <div class="range-value mt-2">
                        <input
                            :placeholder="constFilter.cog_min"
                            type="number"
                            :min="constFilter.cog_min"
                            v-model="filterData.cog_min"
                            class="range-item"
                            @blur="blurHandler($event, 'cog_min')"
                            @keyup="inputHandler($event, 'cog_min')"
                        />
                        <input
                            :placeholder="constFilter.cog_max"
                            type="number"
                            :max="constFilter.cog_max"
                            v-model="filterData.cog_max"
                            class="range-item"
                            @blur="blurHandler($event, 'cog_max')"
                            @keyup="inputHandler($event, 'cog_max')"
                        />
                    </div>
                </div>
                <div class="mt-7">
                    <span>{{ $t('FBA Sellers Qty') }}</span>
                    <div class="range-value mt-2">
                        <input
                            :placeholder="constFilter.fba_sellers_qty_min"
                            type="number"
                            :min="constFilter.fba_sellers_qty_min"
                            v-model="filterData.fba_sellers_qty_min"
                            class="range-item"
                            step="1"
                            @blur="blurHandler($event,'fba_sellers_qty_min')"
                            @keyup="inputHandler($event, 'fba_sellers_qty_min')" 
                        />
                        <input
                            :placeholder="constFilter.fba_sellers_qty_max"
                            type="number"
                            :max="constFilter.fba_sellers_qty_max"
                            v-model="filterData.fba_sellers_qty_max"
                            class="range-item"
                            step="1"
                            @blur="blurHandler($event,'fba_sellers_qty_max')"
                            @keyup="inputHandler($event, 'fba_sellers_qty_max')"
                        />
                    </div>
                </div>
                <div class="mt-7">
                    <span>{{ $t('Profit, ') + cCodeToSymbol[marketCurrCode[filterData.marketplace]] }}</span>
                    <div class="range-value mt-2">
                        <input
                            :placeholder="constFilter.profit_fba_min"
                            type="number"
                            :min="constFilter.profit_fba_min"
                            v-model="filterData.profit_fba_min"
                            class="range-item"
                            @blur="blurHandler($event, 'profit_fba_min')"
                            @keyup="inputHandler($event, 'profit_fba_min')"
                        />
                        <input
                            :placeholder="constFilter.profit_fba_max"
                            type="number"
                            :max="constFilter.profit_fba_max"
                            v-model="filterData.profit_fba_max"
                            class="range-item"
                            @blur="blurHandler($event, 'profit_fba_max')"
                            @keyup="inputHandler($event, 'profit_fba_max')"
                        />
                    </div>
                </div>
            </v-col>
            <v-col lg="2" sm="12" md="12">
                <div>
                    <span>{{ $t('ROI, %') }}</span>
                    <div class="range-value mt-2">
                        <input
                            :placeholder="constFilter.roi_min"
                            type="number"
                            :min="constFilter.roi_min"
                            v-model="filterData.roi_min"
                            class="range-item"
                            @blur="blurHandler($event, 'roi_min')"
                            @keyup="inputHandler($event, 'roi_min')"
                        />
                        <input
                            :placeholder="constFilter.roi_max"
                            type="number"
                            :max="constFilter.roi_max"
                            v-model="filterData.roi_max"
                            class="range-item"
                            @blur="blurHandler($event, 'roi_max')"
                            @keyup="inputHandler($event, 'roi_max')"
                        />
                    </div>
                </div>
                <div class="mt-7">
                    <span>{{ $t('BuyBox price, ') + cCodeToSymbol[marketCurrCode[filterData.marketplace]] }}</span>
                    <div class="range-value mt-2">
                        <input
                            :placeholder="constFilter.buy_box_price_min"
                            type="number"
                            :min="constFilter.buy_box_price_min"
                            v-model="filterData.buy_box_price_min"
                            class="range-item"
                            @blur="blurHandler($event, 'buy_box_price_min')"
                            @keyup="inputHandler($event, 'buy_box_price_min')"
                        />
                        <input
                            :placeholder="constFilter.buy_box_price_max"
                            type="number"
                            :max="constFilter.buy_box_price_max"
                            v-model="filterData.buy_box_price_max"
                            class="range-item"
                            @blur="blurHandler($event, 'buy_box_price_max')"
                            @keyup="inputHandler($event, 'buy_box_price_max')"
                        />
                    </div>
                </div>
                <div class="mt-7">
                    <span>{{ $t('Deal Price, $') }}</span>
                    <div class="range-value mt-2">
                        <input
                            :placeholder="constFilter.deal_price_min"
                            type="number"
                            :min="constFilter.deal_price_min"
                            v-model="filterData.deal_price_min"
                            class="range-item"
                            @blur="blurHandler($event, 'deal_price_min')"
                            @keyup="inputHandler($event, 'deal_price_min')"
                        />
                        <input
                            :placeholder="constFilter.deal_price_max"
                            type="number"
                            :max="constFilter.deal_price_max"
                            v-model="filterData.deal_price_max"
                            class="range-item"
                            @blur="blurHandler($event, 'deal_price_max')"
                            @keyup="inputHandler($event, 'deal_price_max')"
                        />
                    </div>
                </div>
            </v-col>
        </v-row>
        <div class="d-flex justify-end filter-buttons">
            <v-btn
                color="primary"
                outlined
                :loading="$props.loading"
                style="margin: 10px"
                @click="closeFilter"
            >
                {{ $t('Clear Filter') }}
            </v-btn>
            <v-btn
                :loading="$props.loading"
                style="margin: 10px"
                color="primary"
                @click="updateFilter"
            >
                {{ $t('Apply Filters') }}
            </v-btn>
        </div>
    </v-card>
   </transition>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import store from '@/store'
import { useRouter } from '@core/utils'
import Vue from 'vue'
import { marketCurrCode, cCodeToSymbol } from "@/plugins/helpers"
import {
  mdiClose,
  mdiFeather,
  mdiAlert,
  mdiBiohazard,
  mdiGlassTulip,
  mdiPackageVariantClosed,
  mdiWater,
  mdiLockOpenVariant, mdiLock,
  mdiAnimation
} from "@mdi/js";

export default {
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    },
    components: {
        DatePicker
    },
    setup(props) {
        const rating = ref(store.state.filter.rating)
        const createdRange = ref([])
        const { route, router } = useRouter()
        const amzMarketplaces = computed(() => Object.entries(constFilter.value.amz_categories).map(([k, v]) => (k)))
        const amzCategories = computed(() => constFilter.value?.amz_categories[filterData.value?.marketplace])
        const filterData = computed(({
            get() {
                return store.getters['filter/filterData']
            },
            set(val) {
                store.commit("filter/SET_FILTER_DATA", val)
            }
        }))
        const constFilter = computed(() => store.getters['filter/constFilter'])
        const queryParams = computed(({
            get() {
                return store.getters['filter/queryParams']
            },
            set(val) {
                store.commit("filter/SET_QUERY_PARAMS", val)
            }
        }))
        const redFlags = computed(() => {
            let red_flags = {}
            for (let key in filterData.value) {
                if(key.includes('red_flag')) red_flags[key] = null
            }
            return red_flags
        })
        const statusItems = [
            { text: 'All', value: 0 },
            { text: 'Verified', value: 2 },
            { text: 'Unverified', value: 1 }
        ]
        const updateUrlFilter = () => router.replace({name: route.value.name, query: queryParams.value, params: { savePosition: true }}).catch(()=>{})
        const closeFilter = () => {
            createdRange.value = null
            Object.keys(rating.value).forEach(v => rating.value[v] = true)
            store.commit("filter/CLEAR_SAVED_FILTER_DATA")
            store.commit("filter/SET_SKELETON_FILTER_DATA_FROM_CONST")
            store.commit("filter/CLEAR_QUERY_PARAMS")
            updateUrlFilter()
            updateFilter()
        }
        const updateFilter = () => store.commit("filter/UPDATE_FILTER")
        const saveFilter = (obj) => store.commit("filter/SET_SAVED_FILTER_DATA", obj)
        const changeRedFlad = (e, column) => {
            queryParams.value[column] = e
            if(!e) {
                delUrlParam(column, true)
            }else {
                saveFilter({[column]: e})
                updateUrlFilter()
            }
        }
        const changeSupplier = (e) => {
            if(!e.target._value) {
                delUrlParam('supplier', true)
            }else {
                queryParams.value.supplier = e.target._value
                saveFilter({'supplier': e.target._value})
                updateUrlFilter()
            }
        }
        const getDateTimePlaceholder = () => {
            if(queryParams.value.created_date_start && !queryParams.value.created_date_end) {
                return queryParams.value.created_date_start
            }
            if(queryParams.value.created_date_end && !queryParams.value.created_date_start) {
                return queryParams.value.created_date_end
            }
        }
        watch(() => createdRange.value, (newValue) => {
            if(newValue && newValue[0]) {
                queryParams.value.created_date_start = newValue[0]
                saveFilter({'created_date_start': newValue[0]})
            } else {
                delUrlParam('created_date_start', true)
            }
            if(newValue && newValue[1]) {
                queryParams.value.created_date_end = newValue[1]
                saveFilter({'created_date_end': newValue[1]})
            } else {
                delUrlParam('created_date_end', true)
            }
            updateUrlFilter()
        })
        const changeMarketplace = (e) => {
            if(e == store.state.filter.defaultMarketplace) {
                delUrlParam('marketplace', true)
            }else {
                queryParams.value.marketplace = e
                saveFilter({'marketplace': e})
                updateUrlFilter()
            }
            store.dispatch("filter/getFilterData")
        }
        const changeCategory = (e) => {
            if(e == 'All') {
                delUrlParam('category', true)
            }else {
                queryParams.value.category = e
                saveFilter({'category': e})
                updateUrlFilter()
            }
        }
        const changeStatus = (e) => {
            queryParams.value.status = e
            if(e == 0) {
                delUrlParam('status', true)
            }else {
                saveFilter({'status': e})
                updateUrlFilter()
            }
        }
        const changeMarketRating = () => {
            let text = store.state.filter.defaultData.store_ratings
            if(!rating.value.gold) text = text.replace(/1/g, '')
            if(!rating.value.silver) text = text.replace(/,2/g, '')
            if(!rating.value.bronze) text = text.replace(/,3/g, '')
            if(!rating.value.wooden) text = text.replace(/,4/g, '')
            if(text[0] == ',') text = text.substring(1)
            queryParams.value.store_ratings = text
            if(text == store.state.filter.defaultData.store_ratings) {
                delUrlParam('store_ratings', true)
            } else {
                saveFilter({['store_ratings']: text})
                updateUrlFilter()
            }
        }
        const inputHandler = (e, column) => {
            if(e.target._value) {
                queryParams.value[column] = e.target._value
                updateUrlFilter()
            }else {
                delUrlParam(column)
            }
            saveFilter({[column]: e.target._value})
        }
        const delUrlParam = (column, withSaved = false) => {
            Vue.delete(queryParams.value, column)
            if(withSaved) {
                Vue.delete(store.state.filter.savedFilterData, column)
            }
            updateUrlFilter()
        }
        const blurHandler = (e, column) => {
            if(!e.target._value) {
                Vue.delete(store.state.filter.savedFilterData, column)
            }
        }
        const getRedFlagIcon = (column) => {
            switch (column) {
                case 'red_flag_is_fragile':
                    return mdiGlassTulip
                break;
                case 'red_flag_is_meltable':
                    return mdiWater
                break;
                case 'red_flag_danger_brand_flag':
                    return mdiAlert
                break;
                case 'red_flag_gate':
                    return mdiLock
                break;
                case 'red_flag_bundle':
                    return mdiAnimation
                break;
            }
        }
        const setUrlFilterFromSaved = () => {
            let obj = store.getters['filter/savedFilterData']
            for (var item in obj) {
                let skip = false
                if(item == 'marketplace' && obj[item] == store.state.filter.defaultMarketplace) skip = true
                if(item == 'store_ratings' && obj[item] == store.state.filter.defaultData.store_ratings) skip = true
                if(!skip && item != 'version' && obj[item]) {
                    queryParams.value = {[item]: obj[item]}
                }
            }
            updateUrlFilter()
        }
        const setFilterFromUrl = () => {
            let urlFilters = route.value.query
            for (var filter in route.value.query) {
                if(filter == 'store_ratings') {
                    let ratingsUrl = urlFilters[filter].split(',')
                    rating.value.gold = false
                    rating.value.silver = false
                    rating.value.bronze = false
                    rating.value.wooden = false
                    for (let i = 0; i < ratingsUrl.length; i++) {
                        switch (ratingsUrl[i]) {
                            case '1':
                                rating.value.gold = true
                                break
                            case '2':
                                rating.value.silver = true 
                                break
                            case '3':
                                rating.value.bronze = true
                                break
                            case '4':
                                rating.value.wooden = true
                                break
                        }
                    }
                }
                if(filter == 'created_date_start') {
                    createdRange.value = [urlFilters[filter], createdRange.value[1]]
                }
                if(filter == 'created_date_end') {
                    createdRange.value = [createdRange.value[0], urlFilters[filter]]
                }
                if(filter == 'marketplace') {
                    store.commit("filter/SET_SAVED_FILTER_FROM_URL", {
                        marketplace: store.getters['connect/marketplaces'].includes(urlFilters[filter]) ? urlFilters[filter] : store.getters['filter/savedFilterData'].marketplace || store.state.filter.defaultMarketplace
                    })
                } else {
                    store.commit("filter/SET_SAVED_FILTER_FROM_URL", {
                        [filter]: urlFilters[filter]
                    })
                }
            }
        }
        onMounted(() => {
            /** If we have saved data */
            if(store.getters['filter/savedFilterData']) {
                setFilterFromUrl()
                setUrlFilterFromSaved()
            }
        })
        return {
            getDateTimePlaceholder,
            createdRange,
            rating,
            changeMarketRating,
            marketCurrCode: marketCurrCode(),
            cCodeToSymbol: cCodeToSymbol(),
            blurHandler,
            changeSupplier,
            constFilter,
            getRedFlagIcon,
            amzMarketplaces,
            amzCategories,
            changeRedFlad,
            delUrlParam,
            changeStatus,
            statusItems,
            inputHandler,
            changeMarketplace,
            changeCategory,
            saveFilter,
            updateFilter,
            filterData,
            redFlags,
            closeFilter,
            icons: {
                mdiAlert,
                mdiGlassTulip,
                mdiWater,
                mdiBiohazard,
                mdiPackageVariantClosed,
                mdiFeather,
                mdiLockOpenVariant,
                mdiLock,
                mdiAnimation,
                mdiClose
            },
            redFlagsText: {
                'red_flag_gate': 'Brand Gate',
                'red_flag_asin_parent': 'Variations',
                'red_flag_danger_brand_flag': 'Alert',
                'red_flag_is_oversize': 'Oversize',
                'red_flag_is_fragile': 'Fragile',
                'red_flag_is_meltable': 'Meltable',
                'red_flag_bundle': 'Bundle'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.red-col {
  margin: 0 15px 0 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.my-checkbox .v-label {
  font-size: 10px;
}
.range-value {
    display: flex;
    justify-content: space-between;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
    input[type=number] {
        -moz-appearance:textfield; /* Firefox */
    }
    .range-item {
        width: 45%;
        background-color: #F6F6F7;
        border-radius: 4px;
        height: 27px;
        text-align: center;
    }
    .range-item::placeholder {
        color: #C2C4C9;
    }
    .range-item:focus::placeholder {
        color: transparent;
    }
    .range-item:focus {
        width: 45%;
        border-radius: 4px;
        height: 27px;
        text-align: center;
        outline: none !important;
        border: 2px solid #886BE3;
    }
    .item-different {
        background-color: #F6F6F7;
        color: #C2C4C9;
    }
}
.backdrop-leave-active,.content-leave-active {
  animation: fade-out .75s;
}
.backdrop-enter-active,.content-enter-active {
  animation: fade-in .75s;
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>