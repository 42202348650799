<template>
    <v-btn
        icon
        @click="toggleFilter"
        :color="stateToggleFilter ? 'primary': null"
    > 
        <v-icon
            size="32"
        >
            {{ isFilter ? icons.mdiFilter : icons.mdiFilterOutline }}
        </v-icon>
    </v-btn>
</template>

<script>
import { mdiFilter, mdiFilterOutline } from '@mdi/js'
import store from '@/store'
import { computed } from '@vue/composition-api'
import { useRouter } from '@core/utils'

export default {
    setup() {
        const { route } = useRouter()
        const stateToggleFilter = computed(() => store.state.filter.toggleFilter)
        const toggleFilter = (e) => store.commit("filter/SET_TOGGLE_FILTER", !stateToggleFilter.value)
        const isFilter = computed(() => { 
            if(store.getters['filter/savedFilterData']) {
                const keysObj = Object.keys(store.getters['filter/savedFilterData'])
                let isDifferent = false
                let defaultRating = !!(store.state.filter.defaultData.store_ratings == store.getters['filter/savedFilterData'].store_ratings)
                let length = 0
                for (var item in keysObj) {
                    if(!defaultRating && keysObj[item] != 'version' && keysObj[item] != 'marketplace') {
                        isDifferent = true
                    } else {
                        length++
                    }
                }
                return isDifferent && JSON.stringify(store.getters['filter/savedFilterData']) !== '{}' || length > 3
            }
            return false
        })
        return {
            isFilter,
            stateToggleFilter,
            toggleFilter,
            icons: {
                mdiFilter,
                mdiFilterOutline
            }
        }
    }
}
</script>