<template>
<div class="search">
    <v-text-field v-model="search" :label="$t('Search ...')" append-icon="mdi-magnify" class="mx-4" @input="getProducts($event)" style="margin-bottom: 0"></v-text-field>
</div>
</template>

<script>
import store from '@/store'
import {useVModel} from '@vueuse/core'
import {useRouter} from '@core/utils'
import {ref, computed, getCurrentInstance} from "@vue/composition-api"
import Vue from 'vue'

export default {
    name: 'ProductsSearch',
    setup() {
        const vm = getCurrentInstance().proxy
        const timer = ref(null)
        const { route, router } = useRouter()
        const queryParams = computed(({
            get() {
                return store.getters['filter/queryParams']
            },
            set(val) {
                store.commit("filter/SET_QUERY_PARAMS", val)
            }
        }))
        const search = computed(({
            get() {
                return queryParams.value.search
            },
            set(val) {
                if(val) {
                    let ob = {
                        search: val
                    }
                    queryParams.value = ob
                    router.replace({ name: 'products', query: queryParams.value})
                } else {
                    store.commit("filter/QUERY_DEL_SEARCH")
                    router.replace({ name: 'products', query: queryParams.value})
                }
            }
        }))
        const getProducts = function (e) {
            if (timer.value) {
                clearTimeout(timer.value)
                timer.value = null
            }
            timer.value = setTimeout(() => {
                vm.$emit('updateField', { search: e })
                store.commit("filter/SET_SAVED_FILTER_DATA", { search: e })
            }, 1000)
        }
        return {
            getProducts,
            search
        }
    }
}
</script>
